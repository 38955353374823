<template>
    <div class="bg-white br-8 p-5">
        <div class="row">
            <div class="col-auto">
                <span class="f-18 text-black f-600">
                    {{ titulo }}
                </span>
            </div>
        </div>
        <div class="row">
            <div class="col-auto">
                <span :class="rojo ? 'text-red' : 'text-black'" class=" f-600 f-30">{{ star ? total : tipoValor ? agregarSeparadoresNumero(total) :convertMoney(total) }}</span>
                <i v-if="star" class="icon-star text-warning f-24" />
            </div>
            <div class="ml-auto my-auto">
                <div class="bg-general3 text-white text-center br-20 cr-pointer px-3" @click="$emit('verMas')">
                    <p>
                        Ver Más 
                    </p>
                </div>
            </div>
        </div>
        <div class="row">
            <echart :options="options" class="w-100" />
        </div>
    </div>
</template>

<script>
export default {
    props:{
        titulo:{
            type: String,
            required: true,
        },
        options:{
            type: Object,
            required: true,
        },
        tipoValor: {
            type: Boolean,
            default: false
        },
        rojo: {
            type: Boolean,
            default: false
        },
        star: {
            type: Boolean,
            default: false
        },
        total:{
            type: [Number,String],
            default: 0
        },

    },
}   
</script>

<style lang="css" scoped>
.text-red{
    color: #FF1720;
}
.text-black{
    color: black;
}
</style>